.create-admin-account-form-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 100%;

    form {
        width: 100%;

    }

    .create-admin-account-form {
        width: 35%;
        display: flex;
        justify-content: space-between;
        margin: 20px auto;

        input {
            width: 260px;
            padding: 10px;
        }

        select {
            width: 284px;
            padding: 10px;
        }

        label {
            font-weight: bold;
            font-size: 20px;
        }
    }


    .create-admin-account-btn {
        text-align: center;

        button {
            width: 200px;
            height: 50px;
            margin-top: 50px;
            background-color: #070072;
            border: 1px solid #070072;
            color: white;
            font-size: 17px;
        }
    }
}

.create-admin-account-form-btn {
    width: 60%;
    margin: 50px auto 0px auto;

    button {
        width: 150px;
        height: 45px;
        background-color: white;
        border: 1px solid #070072;
        color: black;
        font-size: 17px;

        &:hover {
            background-color: #070072;
            color: white;
        }
    }
}