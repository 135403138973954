.log-in-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;



    .log-in-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        h1 {
            margin: 40px 0;
            text-align: center;
        }

        form {
            width: 100%;

            .log-in-input-wrapper {
                width: 23%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px auto;

                input {
                    padding: 8px 10px;
                    width: 200px;
                }

                label {
                    font-size: 18px;
                    font-weight: bold;
                }
            }

            .log-in-submit-btn {
                text-align: center;
                margin: 50px 0;

                button {
                    background-color: #070072;
                    color: white;
                    border: 1px solid #070072;
                    height: 45px;
                    width: 180px;
                    letter-spacing: 1.5px;
                    font-size: 18px;
                    cursor: pointer;
                    border-radius: 6px;
                }
            }
        }
    }

}