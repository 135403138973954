.dashboard-sidebar {
    position: fixed;
    height: 100vh;
    z-index: 1;
    left: 0;
    width: 20%;

    header {
        text-align: center;
        margin: 30px 0;
    }

    .count-candidates {
        text-align: center;
        margin-top: 40px;
        color: #000070;

        h4 {
            margin: 10px 0;
        }
    }

    .sidebar-btns {
        text-align: center;
        margin: 20px 0;

        button {
            width: 150px;
            border: 1px solid black;
            padding: 5px 0;
            background-color: white;
        }
    }
}