.dashboard-center {
    position: absolute;
    left: 20%;
    padding: 2% 3%;
    width: 80%;
    box-sizing: border-box;
    background-color: rgb(224, 223, 223);


    .header-dashboard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 15px 20px;
        margin-bottom: 30px;
        border-radius: 5px;

        h2 {
            margin: 0;
        }

        .dashboard-search-box {
            display: flex;
            align-items: center;
            padding-left: 10px;
            background-color: #000070d5;
            color: white;
            border-radius: 5px;

            input {
                border: none;
                outline: none;
                height: 45px;
                padding: 0px 10px;
                width: 350px;
                background: none;
                color: white;

                &::placeholder {
                    color: white;
                }
            }
        }
    }

    .dasboard-candidate-list {
        background-color: white;
        padding: 15px 20px;
        border-radius: 5px;

        h2 {
            margin: 10px 0;
        }

        .border {
            border-top: 1px solid black;
        }

        .candidte-filter-btns {
            margin: 20px 0 50px 0;

            button {
                width: 200px;
                height: 40px;
                background-color: white;
                border: 1px solid #000070;

                &:focus {
                    background-color: #000070d5;
                    color: white;
                }
            }
        }

        .selected {
            color: green;
        }

        .rejected {
            color: red;
        }

        .candidate {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;

            .add-width {
                width: 55%;
            }

            // .candidate-recruitment-btns-width-2 {
            //     width: 40;
            // }

            button {
                background: white;
                border: 1px solid black;
                padding: 5px 10px;

                &:hover {
                    background-color: #000070;
                    border: 1px solid #000070;
                    color: white;
                }
            }

            .add-margin {
                margin: 0px 20px;
            }
        }

        .candidate-bg {
            background-color: rgb(229, 229, 229);
        }
    }
}

button {
    cursor: pointer;
}