.onboard-sec-wrapper {
    width: 70%;
    margin: 100px auto;

    .onboard-sec {
        width: 40%;
        margin: auto;

        display: flex;
        align-items: center;
        justify-content: center;


        p {
            margin: 20px 0;
            font-weight: bold;

            span {
                font-weight: normal;
            }
        }

        >div {
            margin: 10px 0 50px 0;
        }
    }

    .onboard-form-wrapper {

        .onboard-form {
            width: 500px;

            .onboard-form-field {
                display: flex;
                justify-content: space-between;
                margin: 20px 0;

                input {
                    width: 200px;
                }

                input[type='file'] {
                    width: 205px;
                }
            }
        }
    }
}

.submit-btn {
    text-align: center;
    margin: 40px 0;

}

.onboard-back-btn {
    background-color: #070072;
    color: white;
    width: 170px;
    height: 40px;
    border: 1px solid #070072;

    &:hover {
        color: black;
        background-color: white;
    }
}

.onboard-general-btn {
    width: 170px;
    height: 40px;
    border: 1px solid #070072;
    background-color: white;
    color: black;

    &:hover {
        background-color: #070072;
        color: white;
    }
}

.onboard-download-btn {
    padding: 5px 15px;
    background-color: white;
    border: 1px solid black;
}

.change-cursor-type {
    cursor: not-allowed;
}