.application-preview-wrapper {

    width: 80%;
    margin: 20px auto;

    h1 {
        color: #000070;
    }

    .back-btn {
        width: 120px;
        background-color: #000070;
        border: 1px solid #000070;
        color: white;
        height: 35px;
        font-size: 17px;
        border-radius: 4px;

        &:hover {
            background-color: white;
            color: black;
        }
    }

    .candidate-application-btns {
        text-align: center;
        margin: 40px 0 80px 0;

        button {

            background: white;
            border: 1px solid black;
            height: 45px;
            margin: 0 40px;
            padding: 0 30px;

            &:hover {
                background-color: black;
                color: white;

                a {
                    color: white;
                }
            }

            a {
                text-decoration: none;
                color: black;
            }
        }
    }

    .candidate-form-preview {
        border: 1px solid black;
        padding: 30px;
        margin: 60px auto;
        width: 210mm;
    }
}