.candidate-application-preview {

    .form-headder {
        text-align: center;
    }

    .form-purpose {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .candidate-form-img {
            width: 152px;
            height: 152px;

            border: 1px solid black;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .form-category-heading {
        text-decoration: underline;
    }

    .form-field-label {
        font-weight: bolder;
    }

    .candidates-general-details-wrappper {
        margin: 40px 0;

        .candidates-general-details {
            display: flex;
            justify-content: space-between;

            >div {
                width: 45%;
            }
        }

        .candidate-relative-info {
            display: flex;
            justify-content: space-between;

            >div {
                width: 45%;
            }
        }
    }

    .candidates-language-details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
            width: 33%;
        }
    }


    .flex {
        display: flex;
        justify-content: space-between;

        >div {
            width: 45%;
        }
    }

    .candidates-job-experience-details {
        display: flex;
        justify-content: space-between;

        >div {
            width: 45%;
        }
    }

    .candidates-technical-skill-details {
        display: flex;
        justify-content: space-between;

        >div {
            width: 45%;
        }
    }
}